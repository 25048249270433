import moment from 'moment'

export const weekdaysBetween = (
  d1: moment.MomentInput,
  d2: moment.MomentInput,
  isoWeekday: number,
) => {
  // ensure we have valid moment instances
  d1 = moment(d1)
  d2 = moment(d2)
  // figure out how many days to advance to get to the next
  // specified weekday (might be 0 if d1 is already the
  // specified weekday).
  const daysToAdd = (7 + isoWeekday - d1.isoWeekday()) % 7
  const nextTuesday = d1.clone().add(daysToAdd, 'days')
  // if we are already passed the end date, there must not
  // be any of that day in the given period.
  if (nextTuesday.isAfter(d2)) {
    return 0
  }
  // otherwise, just return the whole number of weeks
  // difference plus one for the day we already advanced to
  const weeksBetween = d2.diff(nextTuesday, 'weeks')
  return weeksBetween + 1
}

export const compareDates = (d1, d2) => {
  return moment(d1).isSame(d2)
}

export const getWeekOfYear = dateString => {
  const date = moment.utc(dateString)

  if (!date.isValid()) {
    return 'Invalid date'
  }

  const weekOfYear = date.isoWeek()

  return weekOfYear
}

export const getMondayDateFromWeek = (weekNumber, year) => {
  const date = moment().year(year).isoWeek(weekNumber).startOf('isoWeek');
  return date.toISOString();
}

export const areDatesOnSameDay = (date1, date2) => {
  const formattedDate1 = moment.utc(date1).format('YYYY-MM-DD')
  const formattedDate2 = moment.utc(date2).format('YYYY-MM-DD')
  return formattedDate1 === formattedDate2
}

export const getStatus = date => {
  if (!date) return 'Active'
  const today = new Date()
  const userEndDate = new Date(date)

  return userEndDate < today ? 'Inactive' : 'Active'
}

export const dayInUnixTime = 86400000

export const calculateMonthsDifference = (timeStart: string, timeEnd: string): number => {
  const startDate = moment(timeStart);
  const endDate = moment(timeEnd);

  const monthsDifference = endDate.diff(startDate, 'months');

  return monthsDifference;
}

export const sortObject = (
  array: any[],
  column: string,
  order: string,
): any[] => {
  return array.sort((a, b) => {
    const isDateColumn = column === 'created_at'

    const valueA = isDateColumn ? new Date(a[column]) : a[column]
    const valueB = isDateColumn ? new Date(b[column]) : b[column]

    if (order === 'asc') {
      return a[column] > b[column] ? 1 : -1
    } else {
      return a[column] < b[column] ? 1 : -1
    }
  })
}

export const handleSort = (
  sortBy: string,
  currentSortColumn: string,
  currentSortOrder: string,
): { newSortColumn: string; newSortOrder: string } => {
  let newSortColumn = currentSortColumn
  let newSortOrder = currentSortOrder

  if (sortBy === currentSortColumn) {
    newSortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc'
  } else {
    newSortColumn = sortBy
    newSortOrder = 'asc'
  }

  return { newSortColumn, newSortOrder }
}
