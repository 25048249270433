import React, { useState } from 'react'
import moment from 'moment'
import {
  TimelineGroupBase,
  TimelineItemBase,
  DateHeader,
  TimelineHeaders,
  Unit,
  TimelineMarkers,
  CustomMarker,
} from 'react-calendar-timeline'
import Timeline, { Id } from 'react-calendar-timeline'
import Tippy from '@tippyjs/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import { timelineItemRenderer } from '../../components/TimelineItemRenderer/TimelineItermRenderer'

interface DateHeaderWrapperProps {
  unit: Unit
  className: string
  onClick?: (event: React.MouseEvent) => void
  intervalRenderer?: (context: any) => JSX.Element
}

const DateHeaderWrapper: React.FC<DateHeaderWrapperProps> = ({
  unit,
  className,
  onClick,
  intervalRenderer,
}) => {
  return (
    <div className={className} onClick={onClick}>
      <DateHeader unit={unit} intervalRenderer={intervalRenderer} />
    </div>
  )
}

interface ShowTimelineCalendarProps {
  usersGroups: TimelineGroupBase[]
  usersTimesOffItems: TimelineItemBase<number>[]
  selectItem: (itemId: any) => void,
  isGroupNameClickable?: boolean,
  onGroupNameClick?: (groupId: Id) => void,
}

const ShowTimelineCalendar: React.FC<ShowTimelineCalendarProps> = ({
  usersGroups,
  usersTimesOffItems,
  selectItem,
  isGroupNameClickable,
  onGroupNameClick
}) => {
  const [showYears, setShowYears] = useState(false)
  const [showDays, setShowDays] = useState(true)
  const regexMonthYear = /([A-Za-z]+) (\d{4})/
  const regexMonth = /([A-Za-z]+)/

  function clickToShowYears() {
    setShowYears(true)
    setShowDays(false)
  }

  function clickToShowDays() {
    setShowYears(false)
    setShowDays(true)
  }

  const handleMonthClick = (event: React.MouseEvent) => {
    event.preventDefault()
    const targetText = event.currentTarget.textContent
    const year = targetText?.match(/\d{4}$/)?.[0]
    const month = targetText?.match(/^[a-zA-Z]+/)?.[0]

    if (year && month && !showYears) {
      const spans: NodeListOf<HTMLSpanElement> = document.querySelectorAll(
        'div.date-header-year div.rct-dateHeader span',
      )
      const yearSpan = Array.from(spans).find(
        (span: HTMLSpanElement) => span.textContent === year,
      )

      if (yearSpan) {
        setTimeout(() => {
          yearSpan.click()
        }, 5)
      }
    }
  }

  const TooltipContent = ({ content }: { content: string }) => (
    <div className="item-tooltip">
      <span>
        <strong>
          <span style={{ fontSize: '16px' }}>{content}</span>
        </strong>
      </span>
    </div>
  )

  return (
    <div className="home-timeline position-relative mt-2">
      <div className="sticky-header">
        <div className="timeline-sidebar-header">
          <p>
            <strong>Members ({usersGroups.length})</strong>
          </p>
        </div>
      </div>

      <Timeline
        groups={isGroupNameClickable ? usersGroups
            .map(group => {
              return Object.assign({}, group, {
                title:
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => { if (onGroupNameClick) onGroupNameClick(group.id)} }>
                      {group.title}
                    </div>
              });
            }) : usersGroups}
        items={usersTimesOffItems}
        defaultTimeStart={moment().startOf('day').startOf('day')}
        defaultTimeEnd={moment().add(30, 'day').endOf('day')}
        itemTouchSendsClick={false}
        onItemSelect={itemId => selectItem(itemId)}
        onItemClick={itemId => selectItem(itemId)}
        headerRef={undefined}
        maxZoom={moment.duration(1, 'months').asMilliseconds()}
        minZoom={moment.duration(27, 'days').asMilliseconds()}
        itemRenderer={timelineItemRenderer}
      >
        <TimelineMarkers>
          <CustomMarker
            date={moment().startOf('day').hour(12).minute(0).valueOf()}
          >
            {({ styles }) => {
              const customStyles = {
                ...styles,
                backgroundColor: '#7F39FB',
                zIndex: 100,
                width: '1px',
              }
              return <div style={customStyles} />
            }}
          </CustomMarker>
        </TimelineMarkers>
        <TimelineHeaders>
          <DateHeaderWrapper
            unit="year"
            className={`date-header-year ${!showDays ? '' : 'h-0'}`}
          />
          <DateHeaderWrapper
            unit="month"
            className="date-header-month"
            intervalRenderer={context => {
              return (
                <div
                  className={`rct-dateHeader month-interval ${
                    showDays ? '' : 'info'
                  }`}
                  {...context?.getIntervalProps({})}
                >
                  {context?.intervalContext.intervalText.match(
                    regexMonthYear,
                  ) ? (
                    <div
                      className="month-info-interval monthyear-info"
                      onClick={event => {
                        handleMonthClick(event)
                        showYears ? clickToShowDays() : clickToShowYears()
                      }}
                    >
                      <Tippy
                        content={
                          <TooltipContent content="Change to year view" />
                        }
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          cursor="pointer"
                          className="month-chevron-left"
                        />
                      </Tippy>
                      <span className="month-info-span">
                        {context?.intervalContext.intervalText}
                      </span>
                      <Tippy
                        content={
                          <TooltipContent content="Change to year view" />
                        }
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          cursor="pointer"
                          className="month-chevron-right"
                        />
                      </Tippy>
                    </div>
                  ) : context?.intervalContext.intervalText.match(
                      regexMonth,
                    ) ? (
                    <Tippy
                      content={
                        <TooltipContent content="Change to month view" />
                      }
                    >
                      <div
                        className="month-info-interval month-info"
                        onClick={() =>
                          showYears ? clickToShowDays() : clickToShowYears()
                        }
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          cursor="pointer"
                          className="month-chevron-right"
                        />

                        <span className="month-info-span ">
                          {context?.intervalContext.intervalText}
                        </span>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          cursor="pointer"
                          className="month-chevron-left"
                        />
                      </div>
                    </Tippy>
                  ) : (
                    <span className="month-span">
                      {context?.intervalContext.intervalText}
                    </span>
                  )}
                </div>
              )
            }}
          />
          <DateHeader
            unit="day"
            className={`date-header-day  ${showDays ? '' : 'h-25'}`}
            intervalRenderer={context => {
              const startTime = moment(
                context?.intervalContext.interval.startTime,
              )
              const day = startTime.format('DD')
              const isToday = startTime.isSame(new Date(), 'day')

              return (
                <div
                  className={`rct-dateHeader ${isToday ? 'header-today' : ''}`}
                  {...context?.getIntervalProps({})}
                >
                  <span className="day-span">{day}</span>
                </div>
              )
            }}
          />
        </TimelineHeaders>
      </Timeline>
    </div>
  )
}

export default ShowTimelineCalendar
