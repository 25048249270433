import React, { CSSProperties, useEffect, useState } from 'react'
import moment from 'moment'
import '../../css/teamUtilizationTimeline.scss'
import Timeline, {
  TimelineHeaders,
  DateHeader,
  ReactCalendarItemRendererProps,
  TimelineGroupBase,
  ReactCalendarGroupRendererProps,
  SidebarHeader,
  TimelineItemBase,
  TimelineMarkers,
  CustomMarker,
} from 'react-calendar-timeline'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { current } from '@reduxjs/toolkit'

export interface TeamUtilizationTimelineGroup extends TimelineGroupBase {
  type:
    | 'tribe'
    | 'team'
    | 'Expand/Collapse'
    | 'openTeams'
    | 'devsInBench'
    | 'costOfBench'
  bgColor?: string
  parentId?: string
}

export interface TeamUtilizationTimelineItemBase
  extends TimelineItemBase<number> {
  type:
    | 'open'
    | 'openNotStaffed'
    | 'inProgress'
    | 'openTeams'
    | 'devsInBench'
    | 'costOfBench'
  bgColor?: string
  selectedBgColor?: string
  staffMember?: number
}

interface Props {
  groups: TeamUtilizationTimelineGroup[]
  items: TeamUtilizationTimelineItemBase[]
  timelineStart: Date
  timelineEnd: Date
  timelineStartDefaultValue: Date
  timelineEndDefaultValue: Date
  style?: React.CSSProperties
}

const TeamUtilzationTimeline = ({
  groups,
  items,
  timelineStart,
  timelineEnd,
  timelineStartDefaultValue,
  timelineEndDefaultValue,
}: Props) => {
  const [timelineGroups, setTimelineGroup] = useState<
    TeamUtilizationTimelineGroup[]
  >([])
  const [timelineItems, setTimelineItems] = useState<
    TimelineItemBase<number>[]
  >([])

  const [visibleTimeStart, setVisibleTimeStart] = useState<Date>(
    moment().startOf('day').toDate(),
  )
  const [visibleTimeEnd, setVisibleTimeEnd] = useState<Date>(
    moment().add(65, 'day').endOf('day').toDate(),
  )
  const [openGroups, setOpenGroups] = useState<any>({})
  const [canvasStartTime, setCanvasStartTime] = useState(-1)
  const [canvasEndTime, setCanvasEndTime] = useState(-1)

  useEffect(() => {
    setTimelineGroup(groups)
  }, [groups])

  useEffect(() => {
    if (timelineStart && timelineEnd) {
      setVisibleTimeStart(timelineStart)
      setVisibleTimeEnd(timelineEnd)
    }
  }, [timelineStart, timelineEnd])
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
  const itemRenderer = (reactCalendarProps: ReactCalendarItemRendererProps) => {
    const { itemContext, getItemProps } = reactCalendarProps
    const item = reactCalendarProps.item as TeamUtilizationTimelineItemBase
    const startTime = moment(item.start_time).calendar()
    const endTime = moment(item.end_time).calendar()
    const backgroundColor = itemContext.selected
      ? item.selectedBgColor
      : item.bgColor

    const tooltipContent =
      item.type === 'inProgress' ? (
        <div className="item-tooltip">
          <span className="item-tooltip-title">{itemContext.title}</span>
          <span>{`Start: ${startTime}`}</span>
          <span>{`End: ${endTime}`}</span>
        </div>
      ) : (
        <div className="item-tooltip">
          <span className="item-tooltip-title">{itemContext.title}</span>
          <span>{`Staff Members: ${item.staffMember}`}</span>
        </div>
      )
    const props = getItemProps({
      style: {
        backgroundColor,
      },
    })
    const itemWidth = itemContext.dimensions.collisionWidth
    const itemLeft = itemContext.dimensions.collisionLeft

    const mostLeft = Math.max(itemLeft, canvasStartTime)
    const mostRight = Math.min(itemLeft + itemWidth, canvasEndTime)
    const centerOfVisibleSegment = (mostRight + mostLeft) / 2
    const percentage = clamp(
      (centerOfVisibleSegment - itemLeft) / itemWidth,
      0,
      1,
    )

    const realWidth =
      parseFloat(props.style.width?.toString().replace('px', '') || '0') * 0.85

    const titleContainerStyle: CSSProperties =
      item.type === 'devsInBench' || item.type === 'openTeams'
        ? {}
        : {
            position: 'absolute',
            left: `${realWidth * percentage}px`,
          }
    return (
      <div {...props}>
        <Tippy
          content={tooltipContent}
          followCursor="horizontal"
          plugins={[followCursor]}
          disabled={item.type === 'openTeams' || item.type === 'devsInBench'}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
            }}
          >
            <div style={titleContainerStyle}>{itemContext.title}</div>
          </div>
        </Tippy>
      </div>
    )
  }

  const groupRenderer = (
    reactCalendarGroupRendererProps: ReactCalendarGroupRendererProps<TeamUtilizationTimelineGroup>,
  ) => {
    const { group } = reactCalendarGroupRendererProps
    return (
      <div
        className="d-flex flex-row justify-content-center align-items-center line lh-1 h-100 w-100 bg-white"
        onClick={() => {
          setOpenGroups({ ...openGroups, [group.id]: !openGroups[group.id] })
        }}
      >
        <div
          className={`d-flex flex-row justify-content-center rounded py-1 px-2 group-${group.type}`}
          style={{
            backgroundColor: group.type === 'tribe' ? group.bgColor : '',
          }}
        >
          {group.type === 'Expand/Collapse' ? (
            <FontAwesomeIcon
              icon={openGroups[group.id] ? faChevronUp : faChevronDown}
              size="1x"
            />
          ) : (
            <span className="group-title">{group.title}</span>
          )}
        </div>
      </div>
    )
  }

  const timelineHeaders = () => {
    const diffMonths = moment(visibleTimeEnd).diff(
      moment(visibleTimeStart),
      'months',
    )

    return (
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => {
            return (
              <div className="sidebar-header" {...getRootProps()}>
                <div
                  className="sidebar-header-years" 
                  style={{ display: diffMonths <= 12 ? 'none' : 'flex' }}
                >
                  Years
                </div>
                <div className="sidebar-header-months">Months</div>
                <div
                  className="sidebar-header-weeks"
                  style={{
                    display: diffMonths <= 12 ? 'flex' : 'none',
                    height: diffMonths <= 2 ? 60 : 30,
                  }}
                >
                  Weeks
                </div>
              </div>
            )
          }}
        </SidebarHeader>
        <DateHeader
        unit="year" 
        className="date-header-year"
        style={{ display: diffMonths <= 12 ? 'none' : 'flex' }}
        />
        <DateHeader unit="month" className="date-header-month" />
        <DateHeader
          unit="week"
          className="date-header-week"
          style={{ display: diffMonths <= 12 ? 'flex' : 'none' }}
        />
        <DateHeader
          unit="day"
          className="date-header-day"
          style={{ display: diffMonths <= 2 ? 'flex' : 'none' }}
          intervalRenderer={context => {
            const startTime = moment(
              context?.intervalContext.interval.startTime,
            )
            const weekDay = startTime.format('dd')
            const day = startTime.format('DD')
            const isToday = startTime.isSame(new Date(), 'day')

            return (
              diffMonths <= 2 && (
                <div
                  className={`rct-dateHeader ${
                    weekDay === 'Su' ? 'header-sunday' : ''
                  } ${isToday ? 'header-today' : ''}`}
                  {...context?.getIntervalProps({})}
                >
                  <span className="weekday-span">{weekDay[0]}</span>
                  {'\n'}
                  <span className="day-span">{day}</span>
                </div>
              )
            )
          }}
        />
      </TimelineHeaders>
    )
  }

  function getNewGroups() {
    return timelineGroups.filter(
      g =>
        g.type === 'Expand/Collapse' ||
        g.type === 'tribe' ||
        g.type === 'team' ||
        openGroups[g.parentId ?? ''],
    )
  }

  return (
    <div className="team-utilization-timeline">
      {timelineGroups.length > 0 ? (
        <Timeline
          groups={getNewGroups()}
          items={items}
          itemTouchSendsClick={false}
          canMove={false}
          canResize={false}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          itemRenderer={itemRenderer}
          groupRenderer={groupRenderer}
          maxZoom={moment.duration(65, 'days').asMilliseconds()}
          minZoom={moment.duration(65, 'days').asMilliseconds()}
          lineHeight={40}
          sidebarWidth={150}
          itemHeightRatio={0.8}
          onTimeChange={(
            visibleTimeStart,
            visibleTimeEnd,
            updateScrollCanvas,
          ) => {
            setCanvasStartTime(visibleTimeStart)
            setCanvasEndTime(visibleTimeEnd)
            if (visibleTimeStart && visibleTimeEnd)
              updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
            if (
              timelineStart.valueOf() === timelineStartDefaultValue.valueOf() &&
              timelineEnd.valueOf() === timelineEndDefaultValue.valueOf()
            ) {
              if (visibleTimeStart)
                setVisibleTimeStart(new Date(visibleTimeStart))
              if (visibleTimeEnd) setVisibleTimeEnd(new Date(visibleTimeEnd))
            }
          }}
        >
          <TimelineMarkers>
            <CustomMarker
              date={moment().startOf('day').hour(12).minute(0).valueOf()}
            >
              {({ styles }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: '#7F39FB',
                  zIndex: 100,
                  width: '1px',
                }
                return <div style={customStyles} />
              }}
            </CustomMarker>
          </TimelineMarkers>
          {timelineHeaders()}
        </Timeline>
      ) : (
        <></>
      )}
    </div>
  )
}

export default TeamUtilzationTimeline
