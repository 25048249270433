import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet'
import { Card, Modal } from 'react-bootstrap'
import Calendar from 'reactjs-availability-calendar'
import { User } from '../../types/User'
import {
  useArchiveTimeOffMutation,
  useLazyGetAllTimeoffsQuery,
  useLazyGetAllUsersTimesOffQuery,
  useUpdateTimeOffMutation,
} from '../../redux/timeoff.endpoints'
import moment from 'moment'
import { TimeOff, UsersTimesOff } from '../../types/TimeOff'
import TimeOffForm from '../../components/TimeOffForm/TimeOffForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Id } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { TimelineGroupBase, TimelineItemBase } from 'react-calendar-timeline'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { toast } from 'react-toastify'
import { useLazyGetAllTribesQuery } from '../../redux/tribe.endpoints'
import { useLazyGetAllTeamsQuery } from '../../redux/team.endpoints'
import ShowTimelineCalendar from '../../components/ShowTimelineCalendar/ShowTimelineCalendar'
import diacritics from 'diacritics'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role } from '../../types/Roles'
import { useLazyGetAllUsersQuery } from '../../redux/user.endpoints'
import ModalBambooIntegration from '../../components/ModalBambooIntegration'
import CustomButton from './CustomButton'
import { useTimeoffCalculator } from '../../hooks/useTimeoffCalculator'

interface Props {
  profile: User
}

const Home = ({ profile }: Props) => {
  const navigate = useNavigate()
  const [isEditTimeoffModalOpen, setEditTimeoffModalOpen] = useState(false)
  const {getTotalDaysScheduled, getTotalDaysUsed} = useTimeoffCalculator();
  const [isArchiveTimeOffModalOpen, setIsArchiveTimeOffModalOpen] =
    useState(false)
  const [selectedTimeoff, setSelectedTimeoff] = useState({} as TimeOff)
  const [selectedTimeOffUser, setSelectedTimeOffUser] = useState<User>(
    {} as User,
  )
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedGuildId, setSelectedGuildId] = useState(-1)
  const [selectedTribe, setSelectedTribe] = useState(0)
  const [selectedTeam, setSelectedTeam] = useState(0)
  const [allTimesOff, setAllTimesoff] = useState<TimeOff[]>([])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [formattedTimesOff, setFormattedTimesOff] = useState<any[]>([])
  const [updateTimeOff, updateTimeOffResult] = useUpdateTimeOffMutation()
  const [getAllTimesOff, getAllTimesOffResponse] = useLazyGetAllTimeoffsQuery()
  const [getAllUsers, getAllUsersResponse] = useLazyGetAllUsersQuery()
  const [archiveTimeOff, archiveTimeOffResult] = useArchiveTimeOffMutation()
  const [usersTimesOff, setUsersTimesOff] = useState<UsersTimesOff[]>([])
  const [getUsersTimesOff, getUsersTimesOffResponse] =
    useLazyGetAllUsersTimesOffQuery()
  const [usersGroups, setUsersGroups] = useState<TimelineGroupBase[]>([])
  const [usersTimesOffItems, setUsersTimesOffItems] = useState<
    TimelineItemBase<number>[]
  >([])
  const [getTribes, { data: tribesData }] = useLazyGetAllTribesQuery()
  const [getTeams, { data: teamsData }] = useLazyGetAllTeamsQuery()
  const [bambooModalOpen, setBambooModalOpen] = useState(false)
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<User[]>([])
  useEffect(() => {
    if (selectedTeam) {
      const teamMembers = allUsers.filter(user =>
        user.teamAllocations.some(
          allocation => allocation.teamId === selectedTeam,
        ),
      )
      setSelectedTeamMembers(teamMembers)
    }
  }, [selectedTeam, allUsers])

  const getActiveGuilds = () => {
    const activeGuilds = guilds.filter(guild => {
      return allUsers.some(user => {
        return (
          user.guildId === guild.id &&
          (!user.end_on || new Date(user.end_on) >= new Date())
        )
      })
    })
    return activeGuilds
  }

  const filteredGuilds = guilds
    .filter(guild =>
      selectedTeamMembers.some(member => {
        return (
          member.guildId === guild.id &&
          (!member.endDate || new Date(member.endDate) >= new Date())
        )
      }),
    )
    .sort((a, b) => a.role.localeCompare(b.role))
  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  const sortedTribes = tribesData
    ?.map(tribe => ({ ...tribe }))
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(tribe => {
      const teams = tribe.teams.filter(
        team => !team.endDate || new Date(team.endDate) >= new Date(),
      )
      return teams.length > 0
    })
  const selectableTribes = sortedTribes

  const sortedTeams = teamsData
    ?.map(team => ({ ...team }))
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(team => {
      if (selectedTribe === 0 || team.tribeId === selectedTribe) {
        /*console.log(
          `Team ${team.name} enddate: ${
            team.endDate != null ? new Date(team.endDate) : null
          } vs Today: ${new Date()}`,
        )*/

        return team.endDate === null || new Date(team.endDate) >= new Date()
      }
      return false
    })
  const selectableTeams = sortedTeams

  useEffect(() => {
    setSelectedTeam(0)
  }, [selectedTribe])

  useEffect(() => {
    calculateCalendarItems()
  }, [selectedTribe, selectedTeam, selectedGuildId, searchQuery])

  useEffect(() => {
    if (profile && profile.company) {
      getTribes({
        companyId: profile.company.id,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery: '',
        sortType: 'DESC',
      })
      getTeams(profile.company.id)
    }
  }, [profile])

  const selectItem = (id: Id) => {
    const timeOff = allTimesOff.find(timeOff => timeOff.id == id)
    if (timeOff) {
      const user = usersTimesOff.find(
        userTimesOff => userTimesOff.id == timeOff.userId,
      )
      if (user) {
        setSelectedTimeOffUser(user)
      }
      setSelectedTimeoff(timeOff)
      setEditTimeoffModalOpen(true)
    }
  }

  useEffect(() => {
    getAllUsers({
      companyId: profile.company.id,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    if (profile.isAdmin) {
      getUsersTimesOff(profile.id.toString())
    } else {
      getAllTimesOff(profile.id.toString())
    }
  }, [])

  useEffect(() => {
    if (getAllTimesOffResponse && getAllTimesOffResponse.data) {
      setAllTimesoff(getAllTimesOffResponse.data)
    }
  }, [getAllTimesOffResponse])

  useEffect(() => {
    if (getAllUsersResponse && getAllUsersResponse.data) {
      setAllUsers(getAllUsersResponse.data)
    }
  }, [getAllUsersResponse])

  useEffect(() => {
    if (getUsersTimesOffResponse && getUsersTimesOffResponse.data) {
      setUsersTimesOff(getUsersTimesOffResponse.data)
    }
  }, [getUsersTimesOffResponse])

  useEffect(() => {
    calculateCalendarItems()
  }, [usersTimesOff])

  const calculateCalendarItems = () => {
    const loweredQuery = diacritics.remove(searchQuery.toLowerCase())
    const filteredUsersByQuery =
      searchQuery === ''
        ? allUsers
        : allUsers.filter(user => {
            const firstName = diacritics.remove(user.firstName).toLowerCase()
            const lastName = diacritics.remove(user.lastName).toLowerCase()
            const query = diacritics.remove(loweredQuery).toLowerCase()

            return `${firstName} ${lastName}`.includes(query)
          })

    const filteredUsersByTribe =
      selectedTribe === 0
        ? filteredUsersByQuery
        : filteredUsersByQuery.filter(user =>
            user.teamAllocations.some(
              allocation => allocation.team.tribeId === selectedTribe,
            ),
          )

    const filteredUsersByTeam =
      selectedTeam === 0
        ? filteredUsersByTribe
        : filteredUsersByTribe.filter(user =>
            user.teamAllocations.some(
              allocation => allocation.teamId === selectedTeam,
            ),
          )

    const filteredUsersByGuild =
      selectedGuildId === -1
        ? filteredUsersByTeam
        : filteredUsersByTeam.filter(user => user.guildId === selectedGuildId)

    const sortedUsersByFullName = filteredUsersByGuild.slice().sort((a, b) => {
      const fullNameA = `${a.firstName} ${a.lastName}`.toLowerCase()
      const fullNameB = `${b.firstName} ${b.lastName}`.toLowerCase()
      return fullNameA.localeCompare(fullNameB)
    })

    const groups: TimelineGroupBase[] = sortedUsersByFullName
      .filter(user => {
        if (!user.end_on) return true
        const today = new Date()
        const userEndDate = new Date(user.end_on)
        if (userEndDate < today) return false
        else return true
      })
      .map(userTimesOff => {
        return {
          id: userTimesOff.id,
          title: userTimesOff.firstName.concat(' ', userTimesOff.lastName),
          height: 46,
        }
      })
    const timesOff: TimeOff[] = usersTimesOff
      .map(userTimesOff => userTimesOff.timeoffs)
      .flat()
      .map(timeoff => {
        return {
          approved: timeoff.approved,
          description: timeoff.description,
          endDate: moment(timeoff.endDate).startOf('day').toDate(),
          name: timeoff.name,
          startDate: moment(timeoff.startDate).startOf('day').toDate(),
          userId: timeoff.userId,
          id: timeoff.id,
        }
      })

    const items: TimelineItemBase<number>[] = timesOff.map(timeoff => {
      return {
        id: timeoff.id!,
        group: timeoff.userId,
        start_time: moment(timeoff.startDate).unix() * 1000,
        end_time: moment(timeoff.endDate).endOf('day').unix() * 1000,
        title: timeoff.description,
        canMove: false,
        canResize: false,
        name: '',
      }
    })
    setUsersGroups(groups)
    setAllTimesoff(timesOff)
    setUsersTimesOffItems(items)
  }

  useEffect(() => {
    if (updateTimeOffResult && updateTimeOffResult.isSuccess) {
      setUsersTimesOff([
        ...usersTimesOff.filter(
          userTimesOff => userTimesOff.id != updateTimeOffResult.data.id,
        ),
        updateTimeOffResult.data,
      ])
    }
  }, [updateTimeOffResult])

  useEffect(() => {
    if (archiveTimeOffResult && archiveTimeOffResult.isSuccess) {
      toast.success('Time Off deleted successfully!', {
        toastId: 'archive-timeoff-success',
      })
      if (profile.isAdmin) {
        const newTimeoffItems = usersTimesOffItems.filter(
          timeoffItem => timeoffItem.id != selectedTimeoff.id,
        )
        setUsersTimesOffItems(newTimeoffItems)

        const hasTimeoffsLeft = newTimeoffItems.some(
          item => item.group === selectedTimeOffUser.id,
        )
        if (!hasTimeoffsLeft)
          setUsersTimesOff(
            usersTimesOff.filter(user => user.id !== selectedTimeOffUser.id),
          )
      } else {
        setAllTimesoff(
          allTimesOff.filter(timeOff => timeOff.id != selectedTimeoff.id),
        )
      }
    }
  }, [archiveTimeOffResult])

  useEffect(() => {
    setFormattedTimesOff(
      allTimesOff.map(
        (value: {
          startDate: string | number | Date
          endDate: string | number | Date
        }) => {
          return {
            from: moment(value.startDate).format('YYYY-MM-DD'),
            to: moment(value.endDate).format('YYYY-MM-DD'),
            middayCheckout: false,
          }
        },
      ),
    )
  }, [allTimesOff])

  const handleOnChange = (
    index: number,
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    setSelectedTimeoff({
      ...selectedTimeoff,
      [event.target.name]: event.target.value,
    })
  }
  const handleDateOnChange = (index: number, field: string, date: Date) => {
    setSelectedTimeoff({ ...selectedTimeoff, [field]: date })
  }
  const openEditTimeOffModal = (timeoff: TimeOff) => {
    setSelectedTimeoff(timeoff)
    setEditTimeoffModalOpen(true)
  }
  const openArchiveTimeOffModal = (timeoff: TimeOff) => {
    setSelectedTimeoff(timeoff)
    setIsArchiveTimeOffModalOpen(true)
  }
  const addTimeOff = () => navigate('/add-timeoff')
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const currentYear = new Date().getFullYear()

  const userTribes = () => {
    const tribeNames = profile.teamAllocations
      ?.map(allocation => allocation.team?.tribe?.name || '')
      .filter(val => !!val)

    const uniqueTribes = tribeNames.filter((element, index) => {
      return tribeNames.indexOf(element) === index
    })

    return uniqueTribes.join(', ')
  }

  const calculateNextTimeOff = () => {
    const now = Date.now()

    const futureDates = allTimesOff
      ? allTimesOff.filter(e => new Date(e.startDate).getTime() > now)
      : []

    const sortedDates = futureDates.sort((a, b) => {
      return moment(a.startDate).diff(b.startDate)
    })

    if (sortedDates && sortedDates.length > 0) {
      const first = sortedDates[0]
      return `${moment(first.startDate).format('MMMM DD')} - ${moment(
        first.endDate,
      ).format('MMMM D')}`
    }
    return ''
  }

  const submitUpdateTimeOff = () => {
    if (
      !moment(selectedTimeoff.startDate, 'YYYY-MM-DD', true).isSameOrBefore(
        moment(selectedTimeoff.endDate, 'YYYY-MM-DD', true),
      )
    ) {
      toast.error('The date ranges are incorrect, please check them.', {
        toastId: 'create-timeoff-error',
      })
      return
    }
    let isOverlapping = false
    const e1start = moment(selectedTimeoff.startDate)
    const e1end = moment(selectedTimeoff.endDate)
    allTimesOff.forEach(timeoff => {
      if (
        timeoff !== selectedTimeoff &&
        timeoff.userId === selectedTimeoff.userId &&
        timeoff.id !== selectedTimeoff.id
      ) {
        const e2start = moment(timeoff.startDate)
        const e2end = moment(timeoff.endDate)
        const overlaps =
          e1start.isSameOrBefore(e2end) && e1end.isSameOrAfter(e2start)
        if (overlaps) {
          toast.warning(
            `The modified Time Off is overlapping with another time off, ${timeoff.startDate}-${timeoff.endDate}`,
            {
              toastId: 'create-timeoff-warning',
            },
          )
          isOverlapping = true
          return
        }
      }
    })
    if (!isOverlapping) {
      const updatedTimeOff = {
        ...selectedTimeoff,
        startDate: moment(selectedTimeoff.startDate).format('YYYY-MM-DD'),
        endDate: moment(selectedTimeoff.endDate).format('YYYY-MM-DD'),
      }
      updateTimeOff({ updatedTimeOff, updatedBy: profile })
      if (profile.isAdmin) {
        getUsersTimesOff(profile.id.toString())
      } else {
        getAllTimesOff(profile.id.toString())
      }
      setEditTimeoffModalOpen(false)
    }
  }

  const onOpenBambooModal = () => {
    setBambooModalOpen(true)
  }

  const onCloseBambooModal = () => {
    setBambooModalOpen(false)
  }

  return (
    <>
      <Helmet title="Time Off" />
      <Modal show={isEditTimeoffModalOpen}>
        <div>
          <div className="p-3">
            <TimeOffForm
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              handleUserSelectOnChange={() => {}}
              index={0}
              timeOffs={[selectedTimeoff]}
              users={profile.isAdmin ? [selectedTimeOffUser] : [profile]}
              handleOnChange={handleOnChange}
              handleDateOnChange={handleDateOnChange}
              startDate={new Date()}
              endDate={new Date()}
              removeTimeOff={() => setEditTimeoffModalOpen(false)}
              isEditing
              currentUser={profile}
            />
          </div>
          <div className="col-md-12 d-flex flex-row justify-content-between p-3 border-top">
            <>
              <button
                className="btn fw-semibold no-decoration-button"
                onClick={() => {
                  openArchiveTimeOffModal(selectedTimeoff)
                  setEditTimeoffModalOpen(false)
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  cursor="pointer"
                  className="me-2"
                />
                Delete Time Off
              </button>
            </>
            <div>
              <button
                className="btn btn-secondary me-2"
                onClick={() => setEditTimeoffModalOpen(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={submitUpdateTimeOff}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={isArchiveTimeOffModalOpen}>
        <Modal.Header className="fw-bold">Delete Time Off?</Modal.Header>
        <Modal.Body>
          <span>
            Are you sure you want to delete this Time Off? The process cannot be
            undone.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 text-center">
            <button
              className="btn btn-secondary me-2"
              onClick={() => setIsArchiveTimeOffModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                archiveTimeOff({ selectedTimeoff, archivedBy: profile })
                setIsArchiveTimeOffModalOpen(false)
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {bambooModalOpen && (
        <ModalBambooIntegration
          isOpen={bambooModalOpen}
          onClose={onCloseBambooModal}
        />
      )}
      <div className="container px-5 my-5">
        <h1>Time Off Calendar</h1>
        {!profile.isAdmin ? (
          <>
            <h4 className="mt-4">Statistics</h4>
            <div className="row">
              <div className="col-4">
                <Card className="calendar-card">
                  <Card.Body>
                    <Card.Title>Details</Card.Title>
                    <div>
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td className="fw-semibold">Name:</td>
                            <td className="text-end">
                              {profile.firstName + ' ' + profile.lastName}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">Supervisor:</td>
                            <td className="text-end">TBD</td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">Guild:</td>
                            <td className="text-end">
                              {guilds.find(g => g.id == profile.guildId)
                                ?.role ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">Tribe:</td>
                            <td className="text-end">{userTribes()}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-4">
                <Card className="calendar-card" style={{ height: '100%' }}>
                  <Card.Body>
                    <Card.Title>Dates Scheduled</Card.Title>
                    <div>
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td className="fw-semibold">Upcoming Time Off:</td>
                            <td className="text-end">
                              {calculateNextTimeOff()}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">Days used so far:</td>
                            <td className="text-end">{getTotalDaysUsed(allTimesOff)}</td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">
                              Total days scheduled:
                            </td>
                            <td className="text-end">
                              {getTotalDaysScheduled(allTimesOff)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-4">
                <Card className="calendar-card" style={{ height: '100%' }}>
                  <Card.Body style={{ maxHeight: '200px', overflow: 'auto' }}>
                    <Card.Title>Manage Times Off</Card.Title>
                    {[...allTimesOff]
                      .filter(timeoff => {
                        return (
                          moment(timeoff.endDate).valueOf() >
                          moment(new Date()).valueOf()
                        )
                      })
                      .sort((a, b) => {
                        const dateA = new Date(a.startDate).getTime()
                        const dateB = new Date(b.startDate).getTime()
                        return dateA > dateB ? 1 : -1
                      })
                      .map((timeoff, index) => {
                        return (
                          <div
                            key={index}
                            className="timesoff-list-item border-bottom border-dark border-opacity-25"
                          >
                            <div className="d-flex justify-content-between">
                              <div>
                                <span>
                                  {moment(timeoff.startDate).format('MMM D')}
                                  {' - '}
                                </span>
                                <span>
                                  {moment(timeoff.endDate).format('MMM D')}
                                </span>
                              </div>
                              {profile.id === timeoff.userId && (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    cursor="pointer"
                                    className="me-2"
                                    onClick={() =>
                                      openArchiveTimeOffModal(timeoff)
                                    }
                                  />
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    cursor="pointer"
                                    onClick={() =>
                                      openEditTimeOffModal(timeoff)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })}
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="my-5">
              <div className="mb-4 text-center">
                <h2
                  style={{ display: 'inline' }}
                >{`January-December ${currentYear}`}</h2>
                <button
                  style={{ display: 'inline', float: 'right' }}
                  className="btn btn-primary"
                  onClick={() => addTimeOff()}
                >
                  + Add New Time Off
                </button>
              </div>

              <div className="mb-4">
                <span className="fw-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      fill="#F8F9FA"
                      stroke="#DEE2E6"
                    />
                  </svg>
                  &nbsp; Available
                </span>
                <span className="mx-4 fw-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      fill="#E9ECEF"
                      stroke="#E9ECEF"
                    />
                  </svg>
                  &nbsp; Weekend
                </span>
                <span className="fw-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      fill="#FFB800"
                      stroke="#FFB800"
                    />
                  </svg>
                  &nbsp; Time Off
                </span>
              </div>
              <Calendar
                bookings={
                  formattedTimesOff.length
                    ? formattedTimesOff
                    : [{ from: null, to: null }]
                }
                showControls={false}
                showCurrentYear={false}
              />
            </div>
          </>
        ) : (
          <>
            <div className="pb-2 mb-2 mt-4 border-bottom d-flex justify-content-between">
              <h2
                className="align-text-bottom mb-0 d-flex align-self-end"
                style={{ lineHeight: '0.7' }}
              >
                Filters
              </h2>
              <div style={{ display: 'flex' }}>
                <CustomButton onClick={onOpenBambooModal} />

                {/* <button
                  className="btn btn-secondary"
                  style={{  }}
                  onClick={onOpenBambooModal}
                >
                  BambooHR
                </button> */}
                <button
                  className="btn btn-primary"
                  onClick={() => addTimeOff()}
                >
                  + Add New Time Off
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <label htmlFor="timeoffSearch">Search</label>
                <input
                  className="form-control timeoff-input"
                  placeholder="Type a Name"
                  id="timeoffSearch"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="col-2">
                <label htmlFor="timeoffGuild">Guild</label>
                <select
                  value={selectedGuildId}
                  onChange={e => setSelectedGuildId(Number(e.target.value))}
                  id="timeoffGuild"
                  className="form-select"
                >
                  <option key={'guild--1'} value={-1}>
                    All
                  </option>
                  {getActiveGuilds()
                    .sort((a, b) => a.role.localeCompare(b.role))
                    .map(guild => (
                      <option key={`guild-${guild.id}`} value={guild.id}>
                        {guild.role}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-2">
                <label htmlFor="timeoffTribe">Tribe</label>
                <select
                  value={selectedTribe}
                  id="timeoffTribe"
                  className="form-select"
                  onChange={e => setSelectedTribe(parseInt(e.target.value))}
                >
                  <option value={0}>All</option>
                  {selectableTribes?.map(tribe => (
                    <option key={`tribe-${tribe.id}`} value={tribe.id}>
                      {tribe.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-2">
                <label htmlFor="timeoffTeam">Team</label>
                <select
                  id="timeoffTeam"
                  className="form-select"
                  value={selectedTeam}
                  onChange={e => setSelectedTeam(parseInt(e.target.value))}
                >
                  <option value={0}>All</option>
                  {selectableTeams?.map(team => {
                    const hasCurrentEngagement = team.engagements?.some(
                      engagement => {
                        const startDate = new Date(engagement.startDate)
                        const endDate = new Date(engagement.endDate)
                        const today = new Date()
                        return startDate <= today && endDate >= today
                      },
                    )
                    const currentEngagement = team.engagements?.find(
                      engagement => {
                        const startDate = new Date(engagement.startDate)
                        const endDate = new Date(engagement.endDate)
                        const today = new Date()
                        return startDate <= today && endDate >= today
                      },
                    )
                    return (
                      <option key={`team-${team.id}`} value={team.id}>
                        {team.name} -{' '}
                        {currentEngagement
                          ? currentEngagement.projectName
                          : 'Open'}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end mt-3">
              <div className="me-3">
                <img src={'/white_rectangle.svg'} />
                <label className="ms-1">Available</label>
              </div>
              <div className="me-3">
                <img src={'/grey_rectangle.svg'} />
                <label className="ms-1">Weekend</label>
              </div>
              <div>
                <img src={'/yellow_rectangle.svg'} />
                <label className="ms-1">Time Off</label>
              </div>
            </div>
            <div className="timeline-calendar">
              {usersTimesOffItems.length && usersGroups.length ? (
                <ShowTimelineCalendar
                  selectItem={selectItem}
                  usersGroups={usersGroups}
                  usersTimesOffItems={usersTimesOffItems}
                  isGroupNameClickable={true}
                  onGroupNameClick={groupId => navigate(`/members/${groupId}`)}
                ></ShowTimelineCalendar>
              ) : (
                <h2 className="text-center">No results found</h2>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Home
