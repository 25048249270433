import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import TribeOrgContainer from '../../components/TribeOrg/TribeOrgContainer'
import TribeOrgHeaderContainer from '../../components/TribeOrg/TribeOrgHeaderContainer'
import '../../css/tribeOrgView.scss'
import { useLazyGetAllTribesOrgQuery } from '../../redux/tribe.endpoints'
import { User } from '../../types/User'
import { Helmet } from 'react-helmet'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { AllGuildTypes, Role } from '../../types/Roles'
import { SelectOption } from '../../types/SelectOption'
import {
  useLazyGetDatesSnapshotsQuery,
  useLazyGetSnapshotsQuery,
  useLazyGetSnapshotsFilteredByDateQuery,
} from '../../redux/team.endpoints'
import moment from 'moment'
import {
  areDatesOnSameDay,
  getMondayDateFromWeek,
  getWeekOfYear,
} from '../../utils/general'
import usePersistentState from '../../hooks/usePersistentState'
import LoadingComponent from '../../components/LoadingComponent/loadingComponent'
import { getYearOptions } from '../../utils/dropdownOptionHelper'

interface Props {
  profile: User
  companyId: number
}

const TribeOrgPage = ({ companyId }: Props) => {
  const today = new Date()
  const currentYear = today.getFullYear().toString()
  const [tribesOrg, setTribesOrg] = useState<any[]>([])
  const [currentTribe, setCurrentTribe] = useState<any[]>([])
  const [selectedYear, setSelectedYear] = useState<SelectOption>({
    value: currentYear,
    label: currentYear,
  })
  const [selectedWeek, setSelectedWeek] = useState<SelectOption>({
    value: '',
    label: '',
  })
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedSnapshot, setSelectedSnapshot] = useState<SelectOption>({
    value: '',
    label: '',
  })
  const [snapshotDates, setSnapshotDates] = useState<SelectOption[]>([])
  const [getTribesOrg, getTribesOrgResponse] = useLazyGetAllTribesOrgQuery()
  const [getSnapshots, getSnapshotsResponse] = useLazyGetSnapshotsQuery()
  const [getDatesSnapshots, getDatesSnapshotsResponse] =
    useLazyGetDatesSnapshotsQuery()
  const [getSnapshotsFilteredByDate, getSnapshotsFilteredByDateResponse] =
    useLazyGetSnapshotsFilteredByDateQuery()
  const [isLoading, setIsLoading] = useState(false)

  const [currentIndex, setCurrentIndex] = usePersistentState(
    'TribeOrg_lastViewedIndex',
    0,
  )
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])
  const handleSelect = (selectedIndex: number) => {
    setCurrentIndex(selectedIndex)
  }

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  useEffect(() => {
    if (getDatesSnapshotsResponse && getDatesSnapshotsResponse.data) {
      const dates = getDatesSnapshotsResponse.data.map(snapshotDates =>
        moment.utc(snapshotDates.snapshotDate).toISOString(),
      )
      const setDates = new Set([...dates])

      const snapshotOptions: SelectOption[] = Array.from(setDates)
        .sort(function (a, b) {
          return new Date(b).getTime() - new Date(a).getTime()
        })
        .map(snapshotDates => {
          return {
            value: snapshotDates,
            label: `${formatDate(snapshotDates)} - ${formatDate(
              moment(snapshotDates).add(4, 'days').toDate(),
            )}`,
          }
        })
      setSnapshotDates(snapshotOptions)
    }
  }, [getDatesSnapshotsResponse, selectedYear])
  const formatDate = date => {
    return moment.utc(date).format('MMM D, YYYY')
  }

  useEffect(() => {
    buildSnapshot()
  }, [snapshotDates])

  useEffect(() => {
    getTribesOrg({
      companyId: companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    getDatesSnapshots(companyId)
  }, [])

  useEffect(() => {
    if (getTribesOrgResponse && getTribesOrgResponse.data) {
      const sortedTribes = [...getTribesOrgResponse.data].sort((a, b) =>
        getTeamTribe(a.team) > getTeamTribe(b.team) ? 1 : -1,
      )
      setTribesOrg(sortedTribes)
      setCurrentTribe(sortedTribes)
    }
  }, [getTribesOrgResponse])

  const getGuildNameWithType = (guildType: string) => {
    if (guilds && guilds.length > 0) {
      return guilds.find(guild => guild.type === guildType)?.role ?? ''
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (selectedSnapshot.value !== '') {
      const weekOfYear = getWeekOfYear(selectedSnapshot.value)
      if (weekOfYear.toString() !== selectedWeek.value)
        setSelectedWeek({ value: `${weekOfYear}`, label: `Week ${weekOfYear}` })
      const tribe = buildSnapshot()
      setTribesOrg([...tribe])
      setIsLoading(false)
    } else {
      setTribesOrg([...currentTribe])
    }
  }, [selectedSnapshot])

  const getGuildIdWithType = (guildType: string) => {
    if (guilds && guilds.length > 0) {
      return guilds.find(guild => guild.type === guildType)?.id ?? -1
    } else {
      return -1
    }
  }

  const guildsInfo = [
    {
      name: getGuildNameWithType(AllGuildTypes.Developer),
      color: '#e2d9f3',
      guildId: getGuildIdWithType(AllGuildTypes.Developer),
    },
    {
      name: getGuildNameWithType(AllGuildTypes.QA),
      color: '#FFE5D0',
      guildId: getGuildIdWithType(AllGuildTypes.QA),
    },
    {
      name: getGuildNameWithType(AllGuildTypes.Design),
      color: '#D2F4EA',
      guildId: getGuildIdWithType(AllGuildTypes.Design),
    },
    {
      name: getGuildNameWithType(AllGuildTypes.DevOps),
      color: '#E7F1FF',
      guildId: getGuildIdWithType(AllGuildTypes.DevOps),
    },
  ]

  const getTeamTribe = team => {
    return team ? team.tribeId : 0
  }

  const buildSnapshot = () => {
    if (
      getSnapshotsFilteredByDateResponse &&
      getSnapshotsFilteredByDateResponse.data &&
      snapshotDates.length > 0 &&
      selectedSnapshot.value
    ) {
      const snapshotDate = new Date(selectedSnapshot.value)

      const snapshots = getSnapshotsFilteredByDateResponse.data

      const groupByTribe = [...snapshots]
        .sort((a, b) => (getTeamTribe(a.team) > getTeamTribe(b.team) ? 1 : -1))
        .filter(snap => {
          return areDatesOnSameDay(snap.snapshotDate, snapshotDate)
        })
        .reduce((group, snap) => {
          const { tribe } = snap.team
          let none = 0
          if (tribe) none = tribe.id
          group[none] = group[none] ?? []
          group[none].push(snap)
          return group
        }, {})

      const snapshotTribes: any[] = []

      Object.keys(groupByTribe).forEach(element => {
        const tribe = groupByTribe[element][0].team.tribe
        snapshotTribes.push({
          name: tribe.name,
          updated_at: tribe.updated_at,
          teams: groupByTribe[element]
            .sort((a, b) => (a.team.id > b.team.id ? 1 : -1))
            .map(snap => {
              return {
                ...snap.team,
                engagements: snap.engagements,
                teamAllocations: snap.users.map(user => {
                  const userGuild = snap.userGuilds.find(
                    item => item.id === user.id,
                  )

                  const snapUser = { ...user, guildId: userGuild.guild }
                  return {
                    allocation: 0,
                    role: userGuild ? userGuild.role : '',
                    teamId: snap.team.id,
                    userId: user.id,
                    user: snapUser,
                  }
                }),
              }
            }),
        })
      })

      return snapshotTribes
    }
    return currentTribe
  }
 
  useEffect(() => {
    if (selectedWeek.value) {
      const mondayDate = getMondayDateFromWeek(
        selectedWeek.value,
        selectedYear.value,
      )
      setIsLoading(true);
      setStartDate(moment(mondayDate).format('YYYY-MM-DD'));
      setEndDate(moment(mondayDate).add(4, 'days').format('YYYY-MM-DD'));
      getSnapshotsByDate(
        moment(mondayDate).format('YYYY-MM-DD'),
        moment(mondayDate).add(4, 'days').format('YYYY-MM-DD'),
        mondayDate,
      );
    } else {
      setSelectedSnapshot({ value: '', label: '' });
      setStartDate('');
      setEndDate('');
    }
  }, [selectedWeek])

  const getSnapshotsByDate = async (
    startDate: string,
    endDate: string,
    mondayDate: string,
  ) => {
    await getSnapshotsFilteredByDate({ companyId, startDate, endDate }).then(
      resp => {
        if (resp.status === 'fulfilled') {
          const selectedSnapshot = {
            value: mondayDate,
            label: `${formatDate(mondayDate)} - ${formatDate(
              moment(mondayDate).add(4, 'days').toDate(),
            )}`,
          }
          setSelectedSnapshot(selectedSnapshot)
        }
      },
    )
  }

  const yearOptions = getYearOptions()
  return (
    <div className="container my-4 min-height">
      <Helmet title="Time Off - Tribe Org" />
      <div>
        <Link
          className="no-decoration normal-link"
          style={{ color: '#6C757D' }}
          to="/"
        >
          Home{' '}
        </Link>
        /
        <Link
          className="no-decoration normal-link"
          style={{ color: '#6C757D' }}
          to="/tribeOrg"
        >
          {' '}
          Team Management{' '}
        </Link>
        /
        <Link
          className="no-decoration normal-link"
          style={{ color: '#6C757D' }}
          to="/tribeOrg"
        >
          {' '}
          Tribe Org
        </Link>
      </div>
      <TribeOrgHeaderContainer
        guildsInfo={guildsInfo}
        selectedWeek={selectedWeek}
        selectedYear={selectedYear}
        selectedSnapshot={selectedSnapshot}
        setSelectedWeek={setSelectedWeek}
        setSelectedYear={setSelectedYear}
        setSelectedSnapshot={setSelectedSnapshot}
        snapshotDates={snapshotDates}
        yearOptions={yearOptions}
        setCurrent={() => {
          setSelectedYear({ value: currentYear, label: currentYear })
          setSelectedWeek({ label: '', value: '' })
          setSelectedSnapshot({ label: '', value: '' })
          setTribesOrg(currentTribe)
        }}
      />
      {isLoading && <LoadingComponent />}
      {tribesOrg && (
        <div className="tribe-carousel">
          <div className="tribe-org-week-indicator">
            <h5>
              {selectedSnapshot.value
                ? `Week ${getWeekOfYear(selectedSnapshot.value)}: ${
                    selectedSnapshot.label
                  }`
                : 'Current State'}
            </h5>
          </div>
          <Carousel
            interval={null}
            wrap={false}
            indicators={false}
            prevIcon={
              <FontAwesomeIcon
                icon={faChevronCircleLeft}
                className="carousel-control-prev-icon"
              />
            }
            nextIcon={
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                className="carousel-control-next-icon"
              />
            }
            activeIndex={currentIndex}
            onSelect={handleSelect as any}
          >
            {tribesOrg.map((tribe, index) => (
              <Carousel.Item key={index}>
                <TribeOrgContainer
                  tribesOrg={tribe}
                  isSnapshot={selectedSnapshot.value !== ''}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}

export default TribeOrgPage
