import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DateState {
  startDate?: Date;
  endDate?: Date;
}

const initialState: DateState = {
  startDate: undefined,
  endDate: undefined,
};

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<Date | undefined>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<Date | undefined>) => {
      state.endDate = action.payload;
    },
  },
});

export const { setStartDate, setEndDate } = dateSlice.actions;
export default dateSlice.reducer;
