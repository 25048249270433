import React, { useEffect, useState } from 'react'
import { UsersTimesOff, UsersTribeOrg } from '../../types/TimeOff'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role } from '../../types/Roles'
import { useTimeoffCalculator } from '../../hooks/useTimeoffCalculator'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

interface Props {
  usersInfo: UsersTimesOff[] | UsersTribeOrg[]
  calculateNextTimeOff: (
    timeoff: Array<any>,
  ) => { text: string; colorClass: string }[]
}

export const ReportTableTimeOffContent = ({
  usersInfo,
  calculateNextTimeOff,
}: Props) => {
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const {getTotalDaysUsed,getRemainingDays} = useTimeoffCalculator();
  const [guilds, setGuilds] = useState<Array<Role>>([])

  const startDate = useSelector((state: RootState) => state.date.startDate);
  const endDate = useSelector((state: RootState) => state.date.endDate);

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])
  return (
    <tbody>
      {usersInfo
        .slice()
        .sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase()
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase()
          return nameA.localeCompare(nameB)
        })
        .map((employee, index: number) => {
          const employeeTeams =
            employee.teamAllocations
              ?.map(allocation => allocation.team?.name || '')
              .filter(val => !!val)
              .join(', ') || ''
          const employeeTribes = employee.tribes
            ?.map(tribe => tribe.name)
            .join(',  ')
          const employeeTimeoffs = usersInfo
          let nextTimeOff: { text: string; colorClass: string }[] = []
          let totalDaysUsed = 0;
          let daysScheduled = 0;
          if (employee.timeoffs.length > 0)
          {
            const timeOffsFilterByDate = employee.timeoffs.filter(timeoff => {
              const timeOffStartDate = timeoff.startDate.toString()
              const timeOffEndDate = timeoff.endDate.toString()
              const startDateFormatted = startDate?.toISOString().split("T")[0]
              const endDateFormatted = endDate?.toISOString().split("T")[0]
              if (!startDateFormatted || !endDateFormatted) return true;
              if (!startDate) return timeOffEndDate <= endDateFormatted; 
              if (!endDate) return timeOffStartDate >= startDateFormatted;
              return (
                (timeOffStartDate >= startDateFormatted && timeOffStartDate <= endDateFormatted) || 
                (timeOffEndDate >= startDateFormatted && timeOffEndDate <= endDateFormatted) || 
                (timeOffStartDate <= startDateFormatted && timeOffEndDate >= endDateFormatted) ||
                (timeOffEndDate == startDateFormatted)
              )
            })


            nextTimeOff = calculateNextTimeOff(timeOffsFilterByDate);
            totalDaysUsed = getTotalDaysUsed(timeOffsFilterByDate)
            daysScheduled = getRemainingDays(timeOffsFilterByDate)
          }

          return (
            <tr key={index}>
              <td>
                {employee.firstName} {employee.lastName}
              </td>
              <td>
                {guilds.find(guild => guild.id === employee.guildId)?.role ??
                  ''}
              </td>
              <td>{employeeTribes}</td>
              <td>{employeeTeams}</td>
              <td>{totalDaysUsed}</td>
              <td>{daysScheduled}</td>
              <td>
                {nextTimeOff.map((timeOff, index) => (
                  <div key={index} className={timeOff.colorClass}>
                    {timeOff.text}
                  </div>
                ))}
              </td>
            </tr>
          )
        })}
    </tbody>
  )
}
