import React, { useEffect, useState } from 'react'
import { UsersTimesOff, UsersTribeOrg } from '../../types/TimeOff'

import { ReportTableHeaders } from './ReportTableHeaders'
import { ReportTableTimeOffContent } from './ReportTableTimeOffContent'
import { ReportTableTribeOrgContent } from './ReportTableTribeOrgContent'
import '../../css/reportTable.scss'
import { Role } from '../../types/Roles'
import moment from 'moment'
import { getStatus } from '../../utils/general'
import ReportTableTitleBar from './ReportTableTitleBar'

interface Props {
  usersInfo: UsersTimesOff[] | UsersTribeOrg[]
  calculateNextTimeOff: (timeoff: Array<any>) => {text: string, colorClass: string}[]
  selectedReport: string
  selectedTeam?: string
  tableHeaders: Array<string>
  guilds: Array<Role>
}

const ReportTable = ({
  usersInfo,
  calculateNextTimeOff,
  selectedReport,
  selectedTeam,
  tableHeaders,
  guilds,
  
}: Props) => {
  const [timeoffExportData, setTimeoffExportData] = useState<Array<object>>([
    {},
  ])
  const [tribeOrgExportData, setTribeOrgExportData] = useState<Array<object>>([
    {},
  ])

  interface Column {
    value: string
    widthPx: number
  }

  const getTimeoffExportData = () => {
    const columns: Column[] = [
      { value: 'MEMBER', widthPx: 300 },
      { value: 'GUILD', widthPx: 100 },
      { value: 'TEAM', widthPx: 200 },
      { value: 'TIME OFF', widthPx: 200 },
    ]

    const timeoffEntries = () => {
      const timeoffEntries: Array<object> = []
      const timeoffUserInfo = usersInfo as UsersTimesOff[]
      timeoffUserInfo.forEach(employee => {
        const teams = employee.teamAllocations.map(
          allocation => `${allocation.team.name}`,
        )
        employee.timeoffs.forEach(timeoff => {
          timeoffEntries.push([
            `${employee.firstName} ${employee.lastName}`,
            `${
              guilds.find(guild => guild.id === employee.guildId)?.role ?? '7'
            }`,
            teams.join(', '),
            `${moment(timeoff.startDate).format('MMMM DD')} - ${moment(
              timeoff.endDate,
            ).format('MMMM D')}`,
          ])
        })
      })
      return timeoffEntries
    }
    
    const dataToExport = [
      {
        columns,
        data: timeoffEntries(),
      },
    ]
    return dataToExport
  }

  const getTribeOrgExportData = () => {
    const columns: Column[] = [
      { value: 'MEMBER', widthPx: 300 },
      { value: 'STATUS', widthPx: 100 },
      { value: 'GUILD', widthPx: 100 },
      { value: 'TRIBE', widthPx: 100 },
      { value: 'TEAM(ALLOCATION)', widthPx: 200 },
      { value: 'START DATE', widthPx: 100 },
    ]

    const membersMapped = usersInfo.map(employee => {
      const tribes = employee.tribes.map(tribe => `${tribe?.name}`)
      const teams = employee.teamAllocations.map(
        allocation => `${allocation.team.name} (${allocation.allocation}%)`,
      )
      if (selectedReport === 'Tribe Org') {
        return [
          `${employee.firstName} ${employee.lastName}`,
          `${guilds.find(guild => guild.id === employee.guildId)?.role ?? ''}`,
          getStatus(employee),
          tribes.join(', '),
          teams.join(', '),
          moment(employee.start_on).format('MM/DD/YYYY')
        ]
      }
    })
    const dataToExport = [
      {
        columns,
        data: membersMapped,
      },
    ]
    return dataToExport
  }

  useEffect(() => {
    selectedReport === 'Tribe Org' &&
      setTribeOrgExportData(getTribeOrgExportData())
    selectedReport === 'Time Off' &&
      setTimeoffExportData(getTimeoffExportData())
  }, usersInfo)

  return (
    <>
      <ReportTableTitleBar
        selectedReport={selectedReport}
        exportData={
          selectedReport === 'Time Off' ? timeoffExportData : tribeOrgExportData
        }
      />
      <table className="table">
        <ReportTableHeaders
          tableHeaders={tableHeaders}
          userCount={usersInfo.length}
        />
        {selectedReport === 'Time Off' && (
          <ReportTableTimeOffContent
            usersInfo={usersInfo}
            calculateNextTimeOff={calculateNextTimeOff}
          />
        )}
        {selectedReport === 'Tribe Org' && (
          <ReportTableTribeOrgContent usersInfo={usersInfo} />
        )}
      </table>
    </>
  )
}

export default ReportTable
